<template>
  <div class="echart-line-bar">
    <div class="echart-line-bar-mod" ref="chart" :style="{ width, height }"></div>
  </div>
</template>

<script>
const echarts = require('echarts')

export default {
  name: 'echartLine',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '375px',
    },
    orderMoney: {
      type: Array,
      default: () => [],
    },
    orderCount: {
      type: Array,
      default: () => [],
    },
    ydata: {
      type: Object,
      default: () => {
        return {
          orderMoneyYMax: 0,
          orderYInterval: 0,
          orderMoneyYInterval: 0,
          orderCountYInterval: 0,
        }
      },
    },
    xdata: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    watchProps() {
      const props = {
        orderMoney: this.orderMoney,
        orderCount: this.orderCount,
        ydata: this.ydata,
        xdata: this.xdata,
      }
      return props
    },
  },
  watch: {
    watchProps: {
      handler: function (e) {
        console.log(`e`, e)
        setTimeout(() => {
          this.initCharts()
        })
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      const myChart = echarts.init(this.$refs.chart)
      this.myChart = myChart
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['订单金额', '订单量'],
          x: 'right',
          padding: 0,
          selectedMode: false,
        },
        xAxis: [
          {
            type: 'category',
            data: this.xdata,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '订单金额',
            min: 0,
            // max: this.ydata.orderMoneyYMax,
            // interval: Number(this.ydata.orderMoneyYInterval),
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            name: '订单量',
            min: 0,
            // max: this.ydata.orderCountYMax,
            // interval: Number(this.ydata.orderCountYInterval),
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: '订单金额',
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
            data: this.orderMoney,
            itemStyle: {
              normal: {
                color: '#BE92FB', //改变折线点的颜色
                lineStyle: { color: '#BE92FB' }, //改变折线颜色
              },
            },
          },
          {
            name: '订单量',
            type: 'bar',
            data: this.orderCount,
            barWidth: 20,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#FC9F71', //改变折线点的颜色
                lineStyle: { color: '#FC9F71' }, //改变折线颜色
              },
            },
          },
        ],
      })
    },
  },
}
</script>

<style lang="less" scoped>
.echart-line-bar {
  width: 100%;
  height: 380px;
  margin-top: 25px;
}
</style>
