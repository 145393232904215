<template>
  <div class="echart-radar">
    <div class="echart-radar-mod" ref="chart" :style="{ width, height }"></div>
    <section class="footer-text">
      综合评分：<span class="footer-text-data">{{ score }}</span> 分
    </section>
  </div>
</template>

<script>
const echarts = require('echarts')

export default {
  name: 'echartRadar',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '375px',
    },
    data: {
      type: Array,
      default: () => [],
    },
    score: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      indicator: [
        { name: '质量', max: 5 },
        { name: '按时交付', max: 5 },
        { name: '态度', max: 5 },
        { name: '满意度', max: 5 },
        { name: '响应度', max: 5 },
      ],
    }
  },
  watch: {
    data() {
      setTimeout(() => {
        this.initCharts()
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.initCharts()
    })
    // window.addEventListener('resize', () => {
    //   this.$refs.chart.resize()
    // })
  },
  methods: {
    initCharts() {
      let myChart = echarts.init(this.$refs.chart)
      // const text = this.text
      const indicator = this.indicator
      const data = this.data
      // 绘制图表
      myChart.setOption({
        title: {
          text: null,
        },
        tooltip: {
          enterable: true, //鼠标是否可以移动到tooltip区域内
          trigger: 'item',
        },
        radar: {
          indicator,
        },
        series: [
          {
            name: this.title,
            type: 'radar',
            itemStyle: {
              normal: {
                color: '#4DA2FF', //改变折线点的颜色
                lineStyle: { color: '#4DA2FF' }, //改变折线颜色
              },
            },
            areaStyle: {
              normal: {
                shadowBlur: 13,
                shadowColor: '#4DA2FF',
                shadowOffsetX: 0,
                shadowOffsetY: 10,
                opacity: 0.2,
              },
            },
            data,
          },
        ],
      })
    },
  },
}
</script>

<style lang="less" scoped>
.echart-radar {
  width: 40vw;
  height: 390px;
}
.footer-text {
  position: relative;
  top: -35px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #46546c;
  line-height: 20px;
  text-align: center;
  &-data {
    color: #fc9f71;
  }
}
</style>
