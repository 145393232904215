<!--
 * @Author: gaojingran
 * @Date: 2021-04-12 10:12:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-05 16:44:02
 * @Description: 面包屑
-->

<style lang="less" scoped>
@active_bg: #e2f0ff;

.neo-breadcrumb-nice {
  display: flex;
  align-items: center;
  .neo-breadcrumb-item {
    &.active {
      .label {
        color: #fff !important;
        background-color: #8895ab !important;
      }
    }
    .label {
      padding: 2px 5px;
      cursor: pointer;
      font-size: @font_size_2;
      color: #cfd6e4;
      border-radius: 3px 0px 0px 3px;
      background-color: #ffffff;
      border-top: 1px solid #cfd6e4;
      border-left: 1px solid #cfd6e4;
      border-bottom: 1px solid #cfd6e4;
    }
  }
  .neo-breadcrumb-item:last-of-type .label {
    border-right: 1px solid #cfd6e4;
  }
}
</style>

<template>
  <div class="neo-breadcrumb-nice">
    <div
      class="neo-breadcrumb-item"
      v-for="item in options"
      :class="value === item.value ? 'active' : ''"
      :key="item.value"
    >
      <span class="label" @click="$emit('input', item.value)">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeoBreadcrumb',
  props: {
    value: {},
    // [{ label: xx, value: xx }]
    options: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
