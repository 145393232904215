<template>
  <div class="echart-line">
    <div class="echart-line-mod" ref="chart" :style="{ width, height }"></div>
  </div>
</template>

<script>
const echarts = require('echarts')

export default {
  name: 'echartLine',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '375px',
    },
    XYData: {
      type: Object,
      default: () => {
        return {
          lineXAxios: [],
          lineYData: [],
        }
      },
    },
  },
  computed: {
    watchProps() {
      const props = {
        XYData: this.XYData,
      }
      return props
    },
  },
  watch: {
    watchProps: {
      deep: true,
      handler() {
        this.initCharts()
      },
    },
  },
  mounted() {
    this.initCharts()
  },
  methods: {
    initCharts() {
      const myChart = echarts.init(this.$refs.chart)
      console.log(`this.XYData`, this.XYData)
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.XYData.lineXAxios,
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            data: this.XYData.lineYData,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#4DA2FF', //改变折线点的颜色
                lineStyle: { color: '#4DA2FF' }, //改变折线颜色
              },
            },
          },
        ],
      })
    },
  },
}
</script>

<style lang="less" scoped>
.echart-line {
  width: 40vw;
  height: 380px;
}
</style>
