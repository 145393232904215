<!--
 * @Author: gaojingran
 * @Date: 2021-04-12 10:47:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-08 17:28:55
 * @Description: 统计 card
-->
<style lang="less" scoped>
.statistics-card {
  flex: 1;
  background-color: #fff;
  box-shadow: 0px 2px 15px 0px #f0f6fd;
  border-radius: 10px;
  margin-right: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  &:last-child {
    margin-right: 0px;
  }

  .item-title {
    display: flex;
    align-items: center;
    .title {
      margin-left: 10px;
      color: @text_color_3;
      font-size: @font_size_3;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .number-item {
    margin-top: 20px;
    .number {
      font-size: 36px;
      line-height: 36px;
      font-weight: bold;
    }
    .unit {
      padding-left: 5px;
      font-size: @font_size_2;
    }
  }

  .item-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .title {
      color: @text_color_2;
    }
  }
}
</style>

<template>
  <div class="statistics-card">
    <div class="item-title">
      <NeoIcon :name="icon" :size="42" />
      <span class="title" :title="title">{{ title }}</span>
    </div>
    <div class="number-item ellipsis">
      <span class="number">{{ number }}</span>
      <span class="unit">{{ unit }}</span>
    </div>
    <div class="item-subtitle">
      <span class="title ellipsis mr-4" :title="subTitle">{{ subTitle }}</span>
      <NeoIcon :name="subIcon" :size="12" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsCard',
  props: {
    title: {
      type: String,
      default: '大标题',
    },
    icon: {
      type: String,
      default: '',
    },
    number: {
      type: [Number, String],
      default: 100,
    },
    unit: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '副标题',
    },
    subIcon: {
      type: String,
      default: '',
    },
  },
}
</script>
