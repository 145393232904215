<template>
  <div class="report-title">
    <!-- 日期筛选 -->
    <NeoBreadcrumb :options="dateOptions" v-model="dataValue" />
    <!-- 统计 -->
    <div class="statistics-contianer">
      <StatisticsCard
        icon="c_order"
        subIcon="c_order_small"
        :title="$t('report.cumulative_order')"
        :unit="$t('report.cumulative_order_unit')"
        :subTitle="$t('report.cumulative_order_subtitle')"
        :number="numeral(totalOrders < 0 ? 0 : totalOrders).format('0.[]')"
      />
      <StatisticsCard
        icon="c_corpora"
        subIcon="c_corpora_small"
        :title="$t('report.cumulative_corpora')"
        :unit="$t('report.cumulative_corpora_unit')"
        :subTitle="$t('report.cumulative_corpora_subtitle')"
        :number="numeral(totalCorpus < 0 ? 0 : totalCorpus).format('0.[]')"
      />
      <StatisticsCard
        icon="c_saving"
        subIcon="c_saving_small"
        :title="$t('report.order_volume')"
        :unit="agencyInfo.currency"
        :subTitle="$t('report.order_volume_subtitle')"
        :number="numeral(orderMoneyCount < 0 ? 0 : orderMoneyCount).format('0.[00]')"
      />
      <StatisticsCard
        icon="TranslatedWordcount"
        subIcon="s_time_small"
        :title="$t('report.translated_wordcount')"
        :subTitle="$t('report.translated_wordcount_subtitle')"
        :number="translationWordCountComputed"
      />
    </div>
  </div>
</template>

<script>
import StatisticsCard from '@/views/modules/home/components/StatisticsCard.vue'
import NeoBreadcrumb from '@/components/common/NeoBreadcrumb'
import numeral from 'numeral'

export default {
  components: {
    StatisticsCard,
    NeoBreadcrumb,
  },
  data() {
    return {
      numeral,
      totalOrders: 0,
      totalCorpus: 0,
      orderMoneyCount: 0,
      translationWordCount: 0,
      dataValue: -1,
      dateOptions: [
        {
          label: this.$t('home.all'),
          value: -1,
        },
        {
          label: this.$t('home.one_month'),
          value: 1,
        },
        {
          label: this.$t('home.six_month'),
          value: 6,
        },
        {
          label: this.$t('home.twelve_month'),
          value: 12,
        },
      ],
      agencyInfo: { currency: '' },
    }
  },
  watch: {
    dataValue(val) {
      this.fetchSummaryInfo(val)
    },
  },
  computed: {
    translationWordCountComputed() {
      const num = this.translationWordCount
      const data = num < 0 ? 0 : num
      let result = 0
      if (data >= 10000) {
        result = numeral(data / 10000).format('0.[00]') + 'w'
      } else {
        result = data
      }
      return result
    },
  },
  mounted() {
    this.getAgencyInfo()
    this.fetchSummaryInfo(12)
  },
  methods: {
    // 获取基本企业基本信息
    async getAgencyInfo() {
      try {
        const data = await this.$http({
          key: 'getCusAgencyInfo',
          params: { id: this.$store.state.app.userInfo.agencyId },
        })
        this.agencyInfo = data
        this.agencyInfo.currency = this.getCurrency(this.agencyInfo.currency)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    fetchSummaryInfo(nearlyMonths = null) {
      ;['totalOrders', 'totalCorpus', 'orderMoneyCount', 'translationWordCount'].forEach((item) => {
        this.getData(item, nearlyMonths)
      })
    },
    async getData(name, nearlyMonths) {
      try {
        const data = await this.$http(name, nearlyMonths ? { nearlyMonths } : null)
        this[name] = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val).split(';')[0]
    },
  },
}
</script>

<style lang="less" scoped>
.report-title .statistics-contianer {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
