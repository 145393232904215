<template>
  <div class="echarts-circular">
    <div class="echarts-circular-mod" ref="chart" :style="{ width, height }"></div>
  </div>
</template>

<script>
import numeral from 'numeral'
const echarts = require('echarts')
const colors = [
  '#BE92FB',
  '#8593FD',
  '#4DA2FF',
  '#62c9e1',
  '#87e4c2',
  '#ffc33a',
  '#FC9F71',
  '#ff835b',
  '#FF9493',
  '#fa7383',
  '#eeeeee', //其他,
]

export default {
  name: 'echartsCircular',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '375px',
    },
    isShowLegend: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    circularData: {
      type: Object,
      default: () => {
        return {
          data: [],
          total: 0,
        }
      },
    },
  },
  data() {
    return {
      total: 0,
      numeral,
    }
  },
  computed: {
    watchProps() {
      const props = {
        XYData: this.circularData,
      }
      return props
    },
  },
  watch: {
    watchProps: {
      deep: true,
      handler() {
        this.initCharts()
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      const myChart = echarts.init(this.$refs.chart)
      this.myChart = myChart
      // 绘制图表
      let legend = {
        top: '5%',
        x: 'right',
        orient: 'vertical',
        selectedMode: false,
      }
      if (!this.isShowLegend) {
        legend['data'] = []
      }
      myChart.setOption({
        title: {
          text: this.title,
          textStyle: {
            //文字颜色
            color: '#8895AB',
            //字体风格,‘normal‘,‘italic‘,‘oblique‘
            fontStyle: 'normal',
            //字体粗细 ‘normal‘,‘bold‘,‘bolder‘,‘lighter‘,100 | 200 | 300 | 400...
            fontWeight: 'normal',
            //字体系列
            fontFamily: 'PingFangSC-Medium, PingFang SC',
            //字体大小
            fontSize: 16,
          },
          x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: 'bottom', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
        },
        color: colors,
        tooltip: {
          borderColor: '#fff',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.1)',
          className: '__marked',
          // padding: 0,
          formatter: function (params) {
            const { color, name, percent, value } = params

            return `
                <div class="item1 item">${name}</div>
                <div class="item2 item" style="color:${color}">占比${percent}%</div>
                <div class="item3 item" style="color:${color}">翻译金额${value}</div>
              `
          },
        },
        legend,
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: `总计\n${this.getTotal(this.circularData.total)}`,
            textAlign: 'center',
            fill: '#000',
            height: 51,
            fontSize: 18,
            fontFamily: 'PingFangSC-Medium, PingFang SC',
            fontWeight: 500,
            color: '#46546C',
            lineHeight: 25,
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 1,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              backgroundColor: '#eee',
              borderColor: '#aaa',
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.circularData.data,
          },
        ],
      })
    },
    getTotal(total) {
      if (total >= 10000) {
        return numeral(total / 10000).format('0.[00]') + 'w'
      } else {
        return total
      }
    },
  },
}
</script>

<style lang="less">
.echarts-circular {
  .__marked {
    // width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    height: 85px;
    .item {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #46546c;
      height: 33%;
      line-height: 33%;
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
    .item1 {
      font-size: 12px;
    }
    .item2,
    .item3 {
      font-size: 14px;
    }
  }
}
</style>
