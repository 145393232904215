<template>
  <div class="statistical-report">
    <!-- 报表统计模块 -->
    <reportTitle />
    <!-- 报表展示模块 -->
    <section class="charts">
      <div class="row">
        <header class="header-flex">
          <span class="row-header-title-left">评价统计</span>
          <NeoBreadcrumbNice :options="dateOptionsRow" v-model="dataValueRow1" />
        </header>
        <div class="echarts">
          <echartRadar title="评价统计" :data="radarData" :score="radarScore" ref="echartRadar" />
          <echartLine :XYData="LineXYData" ref="echartLine" />
        </div>
      </div>

      <div class="row flex just-sb">
        <section class="left-chart">
          <header class="header-flex">
            <span class="row-header-title-left">订单统计</span>
            <NeoBreadcrumbNice :options="dateOptionsRow" v-model="dataValueRow2" />
          </header>
          <echartLineBar
            :xdata="lineBarXData"
            :ydata="lineBarYData"
            :orderMoney="orderMoney"
            :orderCount="orderCount"
          />
        </section>
        <section class="right-chart">
          <header class="header-flex">
            <span class="row-header-title-left">产线分布统计</span>
            <NeoBreadcrumbNice :options="dateOptionsRow" v-model="dataValueRow3" />
          </header>
          <echartLineBar
            :xdata="lineBarXData2"
            :ydata="lineBarYData2"
            :orderMoney="orderMoney2"
            :orderCount="orderCount2"
          />
        </section>
      </div>

      <div class="row">
        <header class="header-flex">
          <span class="row-header-title-left">语项统计</span>
          <NeoBreadcrumbNice :options="dateOptionsRow" v-model="dataValueRow4" />
        </header>
        <div class="flex">
          <echartsCircular title="翻译字数" :circularData="circularDataWords" width="33vw" :isShowLegend="false" />
          <echartsCircular title="翻译金额" :circularData="circularDataMoney" width="43vw" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'
import reportTitle from './components/reportTitle.vue'
import echartRadar from './components/echartRadar.vue'
import echartLine from './components/echartLine.vue'
import echartLineBar from './components/echartLineBar.vue'
import echartsCircular from './components/echartsCircular.vue'
import NeoBreadcrumbNice from '@/components/common/NeoBreadcrumbNice.vue'

export default {
  components: {
    reportTitle,
    echartRadar,
    echartLine,
    echartLineBar,
    echartsCircular,
    NeoBreadcrumbNice,
  },
  data() {
    return {
      dataValueRow1: 12,
      dataValueRow2: 12,
      dataValueRow3: 12,
      dataValueRow4: 12,
      /*评价统计*/
      // 雷达图
      radarData: [
        {
          value: [],
        },
      ],
      radarScore: 0,
      // 折线图
      LineXYData: {},
      // 语项统计-环形图
      // 这两个环形图的name必须保持一致
      circularDataWords: { data: [{ value: 0, name: '搜索引擎' }] },
      circularDataMoney: { data: [{ value: 0, name: '搜索引擎' }] },
      dateOptionsRow: [
        {
          label: this.$t('home.twelve_month'),
          value: 12,
        },
        {
          label: this.$t('home.third_month_short'),
          value: 6,
        },
        {
          label: this.$t('home.one_month_short'),
          value: 1,
        },
      ],
      // 订单统计
      orderMoney: [],
      orderCount: [],
      lineBarXData: [],
      lineBarYData: {},
      orderMoney2: [],
      orderCount2: [],
      lineBarXData2: [],
      lineBarYData2: {},
      moment,
      numeral,
    }
  },
  mounted() {
    // 请求所有的数据分发给子组件
    this.fetchAllCharts(12)
  },
  watch: {
    // report_remarkOverview 评价统计概览
    // report_remark 评价统计
    dataValueRow1(val) {
      this.fetchRemarkOverview('report_remarkOverview', val)
      this.fetchRemark('report_remark', val)
    },
    // report_price 订单统计
    dataValueRow2(val) {
      this.fetchPrice('report_price', val)
    },
    // report_priceLine 产品线分布统计
    dataValueRow3(val) {
      this.fetchPriceLine('report_priceLine', val)
    },
    // report_pair 语项统计
    dataValueRow4(val) {
      this.fetchPair('report_pair', val)
    },
  },
  methods: {
    // report_remarkOverview 评价统计概览
    // report_remark 评价统计
    // report_price 订单统计
    // report_priceLine 产品线分布统计
    // report_pair 语项统计
    fetchAllCharts(nearlyMonths) {
      this.fetchRemarkOverview('report_remarkOverview', nearlyMonths)
      this.fetchRemark('report_remark', nearlyMonths)
      this.fetchPrice('report_price', nearlyMonths)
      this.fetchPriceLine('report_priceLine', nearlyMonths)
      this.fetchPair('report_pair', nearlyMonths)
    },
    // 评价统计概览
    async fetchRemarkOverview(name, nearlyMonths) {
      const data = await this.$http(name, { nearlyMonths })
      // radarData
      let { quality, timeliness, attitude, satisfaction, response } = data
      quality = quality ?? 0
      timeliness = timeliness ?? 0
      attitude = attitude ?? 0
      satisfaction = satisfaction ?? 0
      response = response ?? 0
      this.radarData = [{ value: [quality, timeliness, attitude, satisfaction, response] }]
      this.radarScore = data.score
    },
    // 评价统计
    async fetchRemark(name, nearlyMonths) {
      const data = await this.$http(name, { nearlyMonths })
      const m = nearlyMonths
      const LineXYData = {}
      const lineXAxios = data.map((o) => {
        const format = m === 1 ? this.$t('dateFormat.e') : this.$t('dateFormat.d')
        return moment(o.statisticTime).format(format)
      })
      const LineYData = data.map((o) => Number(o.score))
      LineXYData.lineXAxios = lineXAxios
      LineXYData.lineYData = LineYData
      this.LineXYData = LineXYData
    },
    // 订单统计
    async fetchPrice(name, nearlyMonths) {
      const data = await this.$http(name, { nearlyMonths })
      const m = nearlyMonths
      const ydata = {
        orderMoneyYMax: 0,
        orderMoneyYInterval: 0,
        orderCountYMax: 0,
        orderCountYInterval: 0,
      }
      let xdata = data.map((item) => moment(item.statisticTime).format(this.$t('dateFormat.d')))
      const orderMoney = data.map((item) => Number(item.price))
      const orderCount = data.map((item) => Number(item.count))
      ydata.orderMoneyYMax = numeral(
        Math.max.apply(
          Math,
          data.map((o) => o.price)
        ) * 1.15
      ).format('0.[00]')
      ydata.orderCountYMax = numeral(
        Math.max.apply(
          Math,
          data.map((o) => o.count)
        ) * 1.15
      ).format('0.[]')
      ydata.orderMoneyYInterval = numeral(ydata.orderMoneyYMax / 5).format('0.[00]')
      ydata.orderCountYInterval = numeral(ydata.orderCountYMax / 5).format('0')

      if (m === 1) {
        xdata = data.map((item) => moment(item.statisticTime).format(this.$t('dateFormat.e')))
      }
      this.orderMoney = orderMoney
      this.orderCount = orderCount
      this.lineBarXData = xdata
      this.lineBarYData = ydata
    },
    // 产品线分布统计
    async fetchPriceLine(name, nearlyMonths) {
      const data = await this.$http(name, { nearlyMonths })
      const ydata = {
        orderMoneyYMax: 0,
        orderMoneyYInterval: 0,
        orderCountYMax: 0,
        orderCountYInterval: 0,
      }
      let xdata = data.map((item) => item.productLineName)
      const orderMoney = data.map((item) => Number(item.price))
      const orderCount = data.map((item) => Number(item.count))
      ydata.orderMoneyYMax = Number(
        numeral(
          Math.max.apply(
            Math,
            data.map((o) => o.price)
          ) * 1.15
        ).format('0.[00]')
      )
      ydata.orderCountYMax = numeral(
        Math.max.apply(
          Math,
          data.map((o) => o.count)
        ) * 1.15
      ).format('0.[]')

      ydata.orderMoneyYInterval = numeral(ydata.orderMoneyYMax / 5).format('0.[00]')
      ydata.orderCountYInterval = numeral(ydata.orderCountYMax / 5).format('0')

      setTimeout(() => {
        this.orderMoney2 = orderMoney
        this.orderCount2 = orderCount
        this.lineBarXData2 = xdata
        this.lineBarYData2 = ydata
      })
    },
    // 语项统计
    async fetchPair(name, nearlyMonths) {
      const data = await this.$http(name, { nearlyMonths })
      const circularDataWords = {}
      const circularDataMoney = {}
      circularDataWords.total = data.amountTotal
      circularDataMoney.total = data.priceTotal
      const getName = (code = '') => {
        return this.$store.getters['app/getLangNameByCode'](code) || '---'
      }
      circularDataWords.data = data.pairList.map((item, idx) => {
        return {
          name: idx === 10 ? '其他' : `${getName(item.sourceCode)} > ${getName(item.targetCode)}`,
          value: item.amount,
        }
      })
      circularDataMoney.data = data.pairList.map((item, idx) => {
        return {
          name: idx === 10 ? '其他' : `${getName(item.sourceCode)} > ${getName(item.targetCode)}`,
          value: item.price,
        }
      })

      this.circularDataWords = circularDataWords
      this.circularDataMoney = circularDataMoney
    },
  },
}
</script>

<style lang="less" scoped>
.statistical-report {
  padding: 20px;
  .row-header-title-left {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #46546c;
  }
  .row {
    background: #ffffff;
    box-shadow: 0px 4px 17px 0px #edf3fe;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    .left-chart,
    .right-chart {
      width: 45%;
    }
    border-radius: 10px;
  }
  .flex {
    display: flex;
  }
  .just-sb {
    justify-content: space-between;
  }
  .header-flex {
    display: flex;
    justify-content: space-between;
  }
  .echarts {
    display: flex;
    justify-content: space-between;
  }
}
</style>
